import { useCartPageQuery, useFetchCartHeaderQuery } from '@magentoTypes'
import { SkipNavContent, SkipNavLink } from '@reach/skip-nav'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import { ReactNode, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { PreviewModeHeader } from '../PreviewModeHeader/PreviewModeHeader'
import { SamplesNotification } from '../PDP/components/SampleBoxSelector'

export type Props = { children: ReactNode }

const Header = dynamic(() => import('../Header/Header'))
const Footer = dynamic(() => import('../Footer/Footer'))
const FreshChat = dynamic(() => import('../FreshChat/FreshChat'))
const GeoLocPopup = dynamic(() => import('../GeoLocPopup/GeoLocPopup'))

const Layout = ({ children }: Props) => {
  const preview = usePreviewMode()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  useEffect(() => {
    window.addEventListener('storage', async (event: any) => {
      if (event.key == 'addOrUpdateSample') {
        const notification = JSON.parse(event.newValue as string) as SamplesNotification
        await queryClient.refetchQueries(useCartPageQuery.getKey({ cartId: notification.cartId }))
        await queryClient.invalidateQueries(
          useFetchCartHeaderQuery.getKey({ cartId: notification.cartId }),
        )
      }
    })
  }, [])

  return (
    <>
      {preview ? <PreviewModeHeader /> : null}
      <div className="grid min-h-screen grid-rows-[auto,auto,1fr,auto]">
        <SkipNavLink>{t('Skip to main content')}</SkipNavLink>
        <GeoLocPopup />
        <Header />
        <SkipNavContent />
        <main className="block">{children}</main>
        <Footer />
      </div>
      <FreshChat />
      <ToastContainer />
    </>
  )
}

export default Layout
